<template>
	<div id="mian">
		<div class="header">
			<span @click="toUrl('subaccountlist_xq')">基本信息</span>
			<span id="fzcolor">分账商户信息</span>
      <span @click="toUrl('add_subaccountlist_electron')" v-if="detail.mchType != 'PERSONAL' && tabDataOne.authStatus == 'AUTH_SUCCESSED' && tabDataOne.approveStatus == 'PASSED'">电子账簿信息</span>
		</div>
			<div v-auth="'ACCOUNT:ALLOC:MERCHNATS:ACCOUNT'">
        <div class="desc">
        	<h5 class="jsxx">分账商户信息</h5>
        </div>
        <div class="tab1">
        	<table class="tab">
        		<tr class="tab_title">
        			<td>
        				<p>三方分账方编号</p>
        			</td>
        			<td>
        				<p>分账商户登录名</p>
        			</td>
        			<td>
        				<p>商户状态</p>
        			</td>
        			<td>
        				<p>认证状态</p>
        			</td>
        			<td>
        				<p>签约状态</p>
        			</td>
        			<td>
        				<p>图片审核状态</p>
        			</td>
        			<td v-auth="'ACCOUNT:ALLOC:MERCHNATS:ACCOUNT/EDIT'">
        				<p>操作</p>
        			</td>
        		</tr>
        		<tr v-for="(v,i) in tabData" :key="i">
        			<td>
        				<p>{{v.allocMchNo}}</p>
        			</td>
        			<td>
        				<p>{{v.loginName}}</p>
        			</td>
        			<td>
        				<p>{{mchStatus[v.mchStatus]}}</p>
        			</td>
        			<td>
        				<p>{{authStatus[v.authStatus]}}</p>
        			</td>
        			<td>
        				<p>{{signStatus[v.signStatus]}}</p>
        			</td>
        			<td> 
        				<p>{{approveStatus[v.approveStatus]}}</p>
        			</td>
        			<td v-auth="'ACCOUNT:ALLOC:MERCHNATS:ACCOUNT/EDIT'">
        				<p>
                  <a href="javascript:;" @click="showTab(v,'statusShow')" class="lianjie">同步信息</a>
                  <a href="javascript:;" @click="showTab(v,'signShow')" class="lianjie">商户签约</a>
                  <a href="javascript:;" @click="showTab(v,'uploadShow')" class="lianjie">上传图片</a>
                </p>
        			</td>
        		</tr>
        	</table>
        </div>
      </div>

      <!-- 同步信息弹框 -->
      <el-dialog title="同步信息" :visible.sync="statusShow" width="490px">
      	<ul class="overAccount_ul">
      		<li style="width: 240px;margin: 0 auto;">
      			<div class="overAccount_ul_title">选择类型</div>
            <el-select v-model="statusSync" placeholder="选择所属商户">
            	<el-option label="商户状态" value="MCH"></el-option>
              <el-option label="签约状态" value="SIGN"></el-option>
              <el-option label="图片审核状态" value="APPROVE"></el-option>
            </el-select>
      		</li>
      	</ul>
      	<span slot="footer" class="dialog-footer">
      		<el-button class="exitBt" @click="statusShow = false">取 消</el-button>
      		<el-button class="addBt" @click="cloneStatus(tabForm.thirdAccountId,statusSync)">同 步</el-button>
      	</span>
      </el-dialog>

      <!-- 商户签约弹框 -->
      <el-dialog title="商户签约" :visible.sync="signShow" width="490px">
      	<ul class="overAccount_ul">
      		<li style="width: 240px;margin: 0 auto;">
      			<div class="overAccount_ul_title">签约分账商户：{{tabForm.allocMchNo}}</div>
            <div class="lianjie" @click="setSignC(tabForm.thirdAccountId)" style="text-align: center;margin-left: 0;">查看协议内容</div>
            <div class="copyBtn" @click="copyLink(tabForm.thirdAccountId)">复制签约链接</div> 
      		</li>
      	</ul>
      	<span slot="footer" class="dialog-footer">
          <!-- <el-button class="copyBtn exitBt" @click="copyLink(tabForm.allocMchNo)" style="padding:0px 10px;width: auto;">复制签约链接</el-button> -->
          <input type="text" ref="copyLinkInput" id="copyLinkInput" style="width:10px;opacity: 0;">
      		<el-button class="exitBt" @click="signShow = false">取 消</el-button>
      		<el-button class="addBt" @click="setSign(tabForm.thirdAccountId)">签 约</el-button>
      	</span>
      </el-dialog>

      <!-- 上传图片弹框 -->
      <el-dialog title="上传图片" :visible.sync="uploadShow" width="490px">
      	<ul class="overAccount_ul">
      		<li style="width: 240px;margin: 0 auto;">
      			<div class="overAccount_ul_title">选择类型</div>
            <div>
              <el-radio-group v-model="uploadType">
                <el-radio label="add">上传图片</el-radio>
                <el-radio label="edit">修改图片</el-radio>
              </el-radio-group>
            </div>
      		</li>
      	</ul>
      	<span slot="footer" class="dialog-footer">
      		<el-button class="exitBt" @click="uploadShow = false">取 消</el-button>
      		<el-button class="addBt" @click="uploadImg(uploadType)">确 认</el-button>
      	</span>
      </el-dialog>
   
      <el-dialog :title="imgTitle" :visible.sync="uploadImgShow" width="490px">
      	<ul class="overAccount_ul">
      		<div class="upload_ph" style="padding: 0px 20px;box-sizing: border-box;">
              <!-- 个人 -->
      				<div class="uploader1" v-show="formData.mchType =='PERSONAL'">
      					<h4>营业执照</h4>
      					<el-upload class="avatar-uploader" :before-upload="beforeUpload" drag :action="uploadUrl" :show-file-list="false" :on-success="licencePictureSuccess">
      						<img v-if="formData.licencePicture" :src="formData.licencePicture" class="avatar">
      						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.licencePicture" class="avatar-uploader-span" @click.stop="formData.licencePicture = ''"><i class="el-icon-close"></i></span>
      					</el-upload>
      				</div>
               <!-- 企业/个体户 -->
              <div class="uploader1" v-show="formData.mchType !='PERSONAL'">
      					<h4><i v-show="formData.mchType !='PERSONAL'">*</i>营业执照</h4>
                <img v-if="approveImg != 'NOT_PASSED'  &&  approveImg != 'NOT_UPLOAD' && approveImg != 'PASSED'" :src="formData.licencePicture" class="approveImg">
                <div v-else>
                    <el-upload class="avatar-uploader" :before-upload="beforeUpload" drag :action="uploadUrl" :show-file-list="false" :on-success="licencePictureSuccess">
                    <img v-if="formData.licencePicture" :src="formData.licencePicture" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    <span v-if="formData.licencePicture" class="avatar-uploader-span" @click.stop="formData.licencePicture = ''"><i class="el-icon-close"></i></span>
                  </el-upload>
                </div>
      				</div>
      				<div class="uploader1">
      					<h4><i>*</i>法人证件正面照片</h4>
                <!-- <img v-if="approveImg != 'NOT_PASSED'  &&  approveImg != 'NOT_UPLOAD'" :src="formData.idcardNormalPicture" class="approveImg">
                <div v-else> -->
                  <el-upload class="avatar-uploader" :before-upload="beforeUpload" drag :action="uploadUrl" :show-file-list="false" :on-success="idcardNormalPictureSuccess">
                    <img v-if="formData.idcardNormalPicture" :src="formData.idcardNormalPicture" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    <span v-if="formData.idcardNormalPicture" class="avatar-uploader-span" @click.stop="formData.idcardNormalPicture = ''"><i class="el-icon-close"></i></span>
                  </el-upload>
                <!-- </div> -->
      				</div>
      				<div class="uploader1">
      					<h4><i>*</i>法人证件反面照片</h4>
                <img v-if="approveImg != 'NOT_PASSED'  &&  approveImg != 'NOT_UPLOAD' && approveImg != 'PASSED'" :src="formData.idcardReversePicture" class="approveImg">
                <div v-else>
                    <el-upload  class="avatar-uploader" :before-upload="beforeUpload" drag :action="uploadUrl" :show-file-list="false" :on-success="idcardReversePictureSuccess">
                    <img v-if="formData.idcardReversePicture" :src="formData.idcardReversePicture" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    <span v-if="formData.idcardReversePicture" class="avatar-uploader-span" @click.stop="formData.idcardReversePicture = ''"><i class="el-icon-close"></i></span>
                  </el-upload>
                </div>
      				</div>
      				<div class="uploader1">
                <!-- formData.mchType !='INDIVIDUAL_BUSINESS'||formData.mchType!='ENTERPRISE'||formData.mchType!='INDIVIDUAL_BUSINESS' -->
      					<h4><i v-show="formData.mchType=='ENTERPRISE'">*</i>开户许可证</h4>
      					<el-upload class="avatar-uploader" :before-upload="beforeUpload" drag :action="uploadUrl" :show-file-list="false" :on-success="accountOpeningLicenseSuccess">
      						<img v-if="formData.accountOpeningLicense" :src="formData.accountOpeningLicense" class="avatar">
      						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.accountOpeningLicense" class="avatar-uploader-span" @click.stop="formData.accountOpeningLicense = ''"><i class="el-icon-close"></i></span>
      					</el-upload>
      				</div>
      				<div class="uploader1">
      					<h4>其他类型图片1</h4>
      					<el-upload class="avatar-uploader" :before-upload="beforeUpload" drag :action="uploadUrl" :show-file-list="false" :on-success="otherPicture1Success">
      						<img v-if="formData.otherPicture1" :src="formData.otherPicture1" class="avatar">
      						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <span v-if="formData.otherPicture1" class="avatar-uploader-span" @click.stop="formData.otherPicture1 = ''"><i class="el-icon-close"></i></span>
      					</el-upload>
      				</div>
      				<div class="uploader1">
      					<h4>其他类型图片2</h4>
      					<el-upload class="avatar-uploader" :before-upload="beforeUpload" drag :action="uploadUrl" :show-file-list="false" :on-success="otherPicture2Success">
      						<img v-if="formData.otherPicture2" :src="formData.otherPicture2" class="avatar">
      						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
                   <span v-if="formData.otherPicture2" class="avatar-uploader-span" @click.stop="formData.otherPicture2 = ''"><i class="el-icon-close"></i></span>
      					</el-upload>
      				</div>
      			</div>
      	</ul>
      	<span slot="footer" class="dialog-footer">
      		<el-button class="exitBt" @click="onClose()">取 消</el-button>
      		<el-button class="addBt" :disabled="uploadImgsFlag" @click="uploadImgs">确 认</el-button>
      	</span>
      </el-dialog>


			<el-dialog title="分账商户信息" :visible.sync="newShopShow" width="490px">
				<ul class="overAccount_ul">
					<li class="ul_left">
						<div class="overAccount_ul_title">商户状态</div>
						<div><span class="bt">{{mchStatus[tabForm.mchStatus]}}</span><span class="lianjie" @click="cloneStatus(tabForm.thirdAccountId,'MCH')">同步</span></div>
						<div class="overAccount_ul_title">签约状态</div>
						<div><span class="bt">{{signStatus[tabForm.signStatus]}}</span><span class="lianjie" @click="cloneStatus(tabForm.thirdAccountId,'SIGN')">同步</span><span
							 class="lianjie" @click="setSign(tabForm.thirdAccountId)">签约</span><span class="lianjie" @click="setSignC(tabForm.thirdAccountId)">协议内容</span></div>
					</li>
					<li class="ul-right">
						<div class="overAccount_ul_title">认证状态</div>
						<div><span class="bt">{{authStatus[tabForm.authStatus]}}</span></div>
						<div class="overAccount_ul_title">图片审核状态</div>
						<div><span class="bt">{{approveStatus[tabForm.approveStatus]}}</span><span class="lianjie" @click="cloneStatus(tabForm.thirdAccountId,'APPROVE')">同步</span></div>
					</li>
				</ul>
				<span slot="footer" class="dialog-footer">
					<el-button class="exitBt" @click="newShopShow = false">取 消</el-button>
					<el-button class="addBt" @click="newShopShow = false">确 定</el-button>
				</span>
			</el-dialog>
			<div class="cover_view" v-if="showImgUrl!==''" @click="showImgUrl=''">
				<a :href="showImgUrl" target="_blank">
					<img :src="showImgUrl" alt="加载失败">
				</a>
			</div>
			<div class="cover_view" v-if="signHtml!==''" @click="signHtml=''">
				<div v-html="signHtml" class="html"></div>
			</div>
	</div>
</template>
<script>
import {
  getAllocmerchantsDetail,
  getThirdaccounts,
  setThirdaccountsSign,
  setThirdaccountsSignC,
  setImage,
  setThirdaccountsSignUrl,
} from "@/api/user/subaccount.js";
import { URL_API } from "@/utils/apiUrl.js";
import * as imageConversion from "image-conversion";
export default {
  data() {
    return {
      newShopShow: false, //新增商户信息展示
      detail: {}, //详情
      mchType: {
        ENTERPRISE: "企业",
        INDIVIDUAL_BUSINESS: "个体工商户",
        PERSONAL: "个人",
      },
      showImgUrl: "",
      tabData: [],
      tabDataOne:{},//取第一条数据     
      mchStatus: {
        null: "",
        NOTACTIVATE: "未激活",
        ACTIVATE: "已激活",
        FROZEN: "已冻结",
        CLOSED: "已关闭",
      },
      authStatus: {
        null: "",
        UNAUTHORIZED: "未认证",
        AUTHENTICATING: "认证中",
        AUTH_FAILED: "认证失败",
        AUTH_SUCCESSED: "认证成功",
      },
      signStatus: {
        null: "",
        NOT_SIGNED: "未签约",
        SIGNED: "已签约",
      },
      approveStatus: {
        null: "",
        NOT_APPROVED: "未审批", 
        PASSED: "审批通过",
        NOT_PASSED: "审批未通过",
        NOT_UPLOAD: "未上传",
      },
      tabForm: "",
      signHtml: "",
      statusShow: false, // 同步消息弹窗
      signShow: false, // 签约弹窗
      uploadShow: false, // 上传图片弹窗
      uploadType: "add", // 上传图片类型
      statusSync: "MCH", // 当前同步的状态
      uploadImgShow: false, // 上传图片弹窗
      uploadUrl: URL_API.upload,
      formData: {
        mchType: "",
        idcardNormalPicture: "", //	true	法人身份证正面照片URL
        idcardReversePicture: "", //	true	法人身份证反面照片URL
        licencePicture: "", //	true	营业执照图片URL
        accountOpeningLicense: "", //true 开户许可证图片URL
        otherPicture1: "",
        otherPicture2: "",
        operateType: "",
      }, // 图片上传
      imgTitle: "", //上传图片弹框标题
      copyLinkTitle:"",
      approveImg:"",
      uploadImgsFlag:false,
      
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    uploadImg(type) {
      if (type == "edit") {
        this.imgTitle = "修改图片";
        this.formData.operateType = "UPDATE";
        this.formData.idcardNormalPicture = this.detail.idcardNormalPicture;
        this.formData.idcardReversePicture = this.detail.idcardReversePicture;
        this.formData.licencePicture = this.detail.licencePicture;
        this.formData.accountOpeningLicense = this.detail.accountOpeningLicense;
        this.formData.otherPicture1 = this.detail.otherPicture1;
        this.formData.otherPicture2 = this.detail.otherPicture2;
      } else {
        this.imgTitle = "上传图片";
        this.formData.operateType = "UPLOAD";
        this.formData.idcardNormalPicture = this.detail.idcardNormalPicture;
        this.formData.idcardReversePicture = this.detail.idcardReversePicture;
        this.formData.licencePicture = this.detail.licencePicture;
        this.formData.accountOpeningLicense = this.detail.accountOpeningLicense;
        this.formData.otherPicture1 = this.detail.otherPicture1;
        this.formData.otherPicture2 = this.detail.otherPicture2;
      }
      this.uploadShow = false;
      this.uploadImgShow = true;
    },
    //复制签约链接
    copyLink(thirdAccountId){
      setThirdaccountsSignUrl(this.$route.query.id, thirdAccountId).then((res) => {
        if (res.resultStatus) {
            this.copyLinkTitle = res.resultData.sign_url;
            let inputs=document.getElementById("copyLinkInput") ;   
            inputs.value=this.copyLinkTitle ;
            inputs.select() //选中节点
            let actions =  document.execCommand("Copy") //指定复制命令(返回的是一个boolean类型)
            if(actions == true){
                this.$message.success("复制成功");
            }else{
                this.$message.error("复制失败");
            }
        }
      });
    },
    // 上传图片
    uploadImgs() {
      console.log("最终提交数据", this.formData);
      // return;
      this.uploadImgsFlag = true;
      setImage(this.$route.query.id, this.formData).then((res) => {
        this.uploadImgsFlag = false;
        if (res.resultStatus) {
          this.uploadImgShow = false;
          this.$message.success("图片上传成功");
          this.getDetail();
        }
      });
    },
    // 关闭上传图片弹窗   
    onClose(){
      this.uploadImgShow = false;
      this.uploadImgsFlag = false;
    },
    //获取详情
    getDetail() {
      getAllocmerchantsDetail(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.detail = res.resultData.mchDetail;
          this.formData.mchType = res.resultData.mchDetail.mchType;
          this.tabData = res.resultData.thirdAccounts;
          if(this.tabData.length>0){
            this.tabDataOne = res.resultData.thirdAccounts[0];
          }
        }
      });
    },
    //同步
    cloneStatus(thirdAccountId, syncType) {
      let data = {
        syncType,
      };
      getThirdaccounts(this.$route.query.id, thirdAccountId, data).then(
        (res) => {
          if (res.resultStatus) {
            this.statusShow = false;
            this.$message.success("同步请求成功");
            this.getDetail();
          }
        }
      );
    },
    // 签约
    setSign(thirdAccountId) {
      setThirdaccountsSign(this.$route.query.id, thirdAccountId).then((res) => {
        if (res.resultStatus) {
          this.signShow = false;
          this.$message.success("签约请求成功");
          this.getDetail();
        }
      });
    },
    //查询签约内容
    setSignC(thirdAccountId) {
      setThirdaccountsSignC(this.$route.query.id, thirdAccountId).then(
        (res) => {
          if (res.resultStatus) {
            this.signHtml = res.resultData;
            this.signShow = false;
          }
        }
      );
    },
    // 浏览单个图片
    showImg(val) {
      this.showImgUrl = val;
    },
    //同步展示
    showTab(value, active) {
      console.log(value,"000000000000000000")
      this.approveImg = value.approveStatus;//图片审核状态
      this.tabForm = value;
      if (active == "uploadShow") {
        // console.log("点击上传图片",value);
        console.log("提交上传1", this.uploadType);
        // this.formData.accountOpeningLicense=""  //开户许可证
        // this.formData.idcardNormalPicture=""  //法人证件正面照
        // this.formData.idcardReversePicture=""  //法人证件反面照
        // this.formData.licencePicture=""  //营业执照

        // this.formData.otherPicture1=""  //其他类型照片1
        // this.formData.otherPicture2=""  //其他类型照片2
        const data = JSON.parse(JSON.stringify(this.detail));

        if (data.mchType == "INDIVIDUAL_BUSINESS") {
          // 分账商户类型为体工商户时
          if (
            !data.licencePicture ||
            !data.idcardNormalPicture ||
            !data.idcardReversePicture
          ) {
            //第一次必传图片没有上传的时候
            console.log("个体工商户初次上传", data);
            this.uploadType = "add";
          } else {
            console.log("个体工商户非初次上传", data);
            this.uploadType = "edit";
          }
        } else if (data.mchType == "PERSONAL") {
          // 分账商户类型为个人时 判断有没有初次上传
          if (!data.idcardNormalPicture || !data.idcardReversePicture) {
            // console.log("个人初次上传", data);
            this.uploadType = "add";
          } else {
            // 非初次上传时,不允许选择上传图片
            // console.log("非个人非初次上传", data);
            this.uploadType = "edit";
          }
        } else {
          // 分账商户类型为企业时
          if (
            !data.licencePicture ||
            !data.idcardNormalPicture ||
            !data.idcardReversePicture ||
            !data.accountOpeningLicense
          ) {
            console.log("企业初次上传", data);
            this.uploadType = "add";
          } else {
            console.log("企业非初次上传", data);
            this.uploadType = "edit";
          }
        }
        this[active] = false;
        this.uploadImg(this.uploadType);
      } else {
        this[active] = true;
      }
      // this.newShopShow = true
    },
    // 跳转页面
    toUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
        },
      });
    },
    // 商户证件上传
    licencePictureSuccess(res) {
      if (res.resultStatus) {
        this.formData.licencePicture = res.resultData.fileLink;
      }
    },
    // 身份证正面照片（法人或联系人）上传
    idcardNormalPictureSuccess(res) {
      if (res.resultStatus) {
        this.formData.idcardNormalPicture = res.resultData.fileLink;
      }
    },
    // 身份证反面照片（法人或联系人）上传
    idcardReversePictureSuccess(res) {
      if (res.resultStatus) {
        this.formData.idcardReversePicture = res.resultData.fileLink;
      }
    },
    // 开户许可证上传
    accountOpeningLicenseSuccess(res) {
      if (res.resultStatus) {
        this.formData.accountOpeningLicense = res.resultData.fileLink;
      }
    },
    // 其他类型图片1上传
    otherPicture1Success(res) {
      if (res.resultStatus) {
        this.formData.otherPicture1 = res.resultData.fileLink;
      }
    },
    // 其他类型图片2上传
    otherPicture2Success(res) {
      if (res.resultStatus) {
        this.formData.otherPicture2 = res.resultData.fileLink;
      }
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "jpeg" ||
        testmsg === "JPEG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      if (!extension) {
        this.$message.error("上传图片支持jpg/jpeg/png/bpm格式");
      }
      if (!extension) {
        return false;
      }
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        console.log(file.size);
        console.log(file.size/1024);
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 2048).then((res) => {
          resolve(res);
        });
      });
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";

.copyBtn{
width: 90px; height: 30px;color: #333333; font-size: 12px;text-align: center; margin: auto;line-height: 32px;border: 1px solid #dce0e6; line-height: 30px; border-radius: 5px; margin-top: 10px; cursor: pointer;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul .bt {
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  font-size: 12px;
  color: #333333;
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 240px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.overAccount_ul .lianjie {
  margin-left: 16px;
  font-size: 12px;
  color: #2d77ff;
  cursor: pointer;
}

.addBt , .addBt:hover , .addBt:focus {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}
.upload_ph p {
  padding-top: 20px;
  padding-bottom: 20px;
}

.upload_ph p span i {
  color: #48b8b6;
  padding-right: 3px;
}

.upload_ph p span {
  font-size: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.upload_ph {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: wrap;
}
.uploader1 {
  width: auto;
    margin: 5px;
}

.uploader1 h4 {
  height: 52px;
  line-height: 52px;
  font-size: 14px;
  color: #000000;
}

.uploader1 h4 i {
  color: #48b8b6;
  padding-right: 3px;
  font-family: "Microsoft YaHei";
}
.avatar-uploader {
  width: 120px;
  height: 120px;
  background: rgba(246, 246, 246, 1);
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.approveImg{
  width: 120px;
    height: 120px;
    display: block;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
}


.cover_view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
}

.cover_view img {
  position: absolute;
  min-height: 200px;
  min-width: 200px;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.html {
  width: 50vw;
  height: 92%;
  padding: 50px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  overflow: auto;
}
/deep/ .agreement-container h2{
  margin:0px auto 30px;
}
/deep/ .agreement-container h2{
    font-size: 18px !important;
}
/deep/ .agreement-container h3 {
    font-size: 16px !important;
    padding: 20px 0px 10px;
}
/deep/ .agreement p {
    font-size: 14px !important;
    line-height: 24px !important;
    margin: 4px 0 !important;
}

/deep/ .agreement h4,/deep/  .agreement h5 {
    margin: 10px 0 !important;
    font-size: 16px !important;
}
/deep/ .md-header-anchor{
  font-size: 16px !important;
}
</style>
