import axios from '@/utils/request'

// 分账商户管理-商户列表
export function allocmerchantsLists(params) {
  // params.loading = true
  return axios({
    url: `/manager/account/allocmerchants`,
    method: 'get',
		params
  })
}
// 分账商户管理-新增商户
export function addAllocmerchants(data) {
  return axios({
    url: `/manager/account/allocmerchants`,
    method: 'post',
		data
  })
}
// 分账商户管理-商户详情
export function getAllocmerchantsDetail(allocMchId) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}`,
    method: 'get'
  })
}
// 分账商户管理-修改商户详情
export function setAllocmerchants(allocMchId, data) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}`,
    method: 'put',
		data
  })
}
// 分账商户管理-编辑结算账户
export function setSettlements(allocMchId, data) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/settlements/${data.settlementId}`,
    method: 'post',
		data
  })
}
// 分账商户管理-删除结算账户
export function delSettlements(allocMchId, settlementId) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/settlements/${settlementId}`,
    method: 'delete'
  })
}
// 分账商户管理-编辑结算产品
export function setProducts(allocMchId, data) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/products/${data.settleType}`,
    method: 'post',
		data
  })
}
// 分账商户管理-删除结算产品
export function delProducts(allocMchId, settleType) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/products/${settleType}`,
    method: 'delete'
  })
}
// 分账商户管理-同步三方账号信息
export function getThirdaccounts(allocMchId, thirdAccountId, params) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/thirdaccounts/${thirdAccountId}/sync`,
    method: 'get',
		params
  })
}
// 分账商户管理-签约
export function setThirdaccountsSign(allocMchId, thirdAccountId) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/thirdaccounts/${thirdAccountId}/sign`,
    method: 'post'
  })
}
// 分账商户管理-查询签约内容
export function setThirdaccountsSignC(allocMchId, thirdAccountId) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/thirdaccounts/${thirdAccountId}/signcontent`,
    method: 'get'
  })
}
// 分账商户管理-上传图片
export function setImage(allocMchId, data) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/picture/upload`,
    method: 'post',
    data
  })
}

//分账商户管理-分账商户信息-商户签约-获取签约链接
export function setThirdaccountsSignUrl(allocMchId, thirdAccountId) {
  return axios({
    url: `/manager/account/allocmerchants/${allocMchId}/thirdaccounts/${thirdAccountId}/signUrl`,
    method: 'post'
  })
}
